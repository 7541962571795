import { useState, useEffect } from "react";
import SelectComponent from "./SelectComponent";
import {
  getFirmsGeoInfos,
  getSchoolsGeoInfos,
  getYears,
} from "services/MappingServices";
import { getDepartements, getRegions } from "services/DashboardServices";
import Region from "models/RegionModel";
import {
  getInterventions,
  getInterventionsItems,
  getTasks,
} from "services/TaskServices";

const STRUCTURE_TYPES = {
  school: "Ecole",
  ens: "ENS",
  eni: "ENI",
  inspection: "Inspection",
  dren: "DREN",
  URAT: "URAT",
  high_school: "Collège",
};

// Rewrite STRUCTURE_TYPES by using object
const SITE_CHOICES: any = {
  school: {
    display: "CBP Ecole",
    filters: { structure_type: ["school"], intervention_type: "cbp" },
  },
  other_cbp: {
    display: "Autres CBP",
    filters: { structure_type: ["eni", "ens", "dren"], intervention_type: "cbp" },
  },
  gateway: { display: "Centre Passerelle", filters: { intervention_type: "gateway" } },
  poolstat: { display: "Pool Stat", filters: { intervention_type: "pool_stat" } },
};

export default function IndicateurSuivi(props: any) {
  const { isOpen, onClose, actionSetMarkers, defineRegion, setters } = props;

  const [allInterventions, setAllInterventions] = useState([]);
  const [years, setYears] = useState([]);
  const [regions, setRegions] = useState([]);
  const [interventions, setInterventions] = useState([]);
  const [sites, setSites] = useState([]);
  const [components, setComponents] = useState([]);
  const [subComponents, setSubComponents] = useState([]);
  const [departements, setDepartements] = useState([]);

  const [selectedIntervention, setSelectedIntervention] = useState();
  const [selectedDepartement, setSelectedDepartement] = useState();
  const [selectedComposant, setSelectedComposant] = useState();
  const [selectedSite, setSelectedSite] = useState();
  const [selectedSubComponent, setSelectedSubComponent] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [selectedRegion, setSelectedRegion] = useState();
  const [loading, setLoading] = useState(false);

  const notNull = (value: any, exceptZero = true) => {
    return exceptZero ? value !== null && value !== undefined : Boolean(value);
  };

  const hierarchyList = [
    setSelectedComposant,
    setSelectedSubComponent,
    setSelectedIntervention,
    setSelectedSite,
    setSelectedYear,
    setSelectedRegion,
    setSelectedDepartement,
  ];
  const [regionsPerId, setRegionPerId] = useState(Object());

  /**
   * When a choice is made on a select the following selects
   * in the form must be reset
   * This method aims to reset the associated state variables
   * @param fromIndex Index when to start the reset from
   */
  const resetVariables = (fromIndex: number) => {
    setters[0]([]);
    for (let index = fromIndex; index < hierarchyList.length; index++) {
      const setter = hierarchyList[index];
      setter(undefined);
    }
  };

  const changeVariable = (value: any, setterIndex: number) => {
    hierarchyList[setterIndex](value);
    resetVariables(setterIndex + 1);
  };

  const withAllOption = (options: any) => {
    return [{ label: "Tout", value: 0 }, ...options];
  };

  const mapCodeToElement = (list: any) => {
    const map = Object();
    list.forEach((element: any, id: any) => {
      map[element.code] = element;
    });
    return map;
  };

  const getSelectOptionsFromMap = (map: any, prefix: string) => {
    if (!map) return [];
    return Object.entries(map).map(([code, element]: any) => ({
      label: `${prefix} ${code}`,
      value: code,
      title: element.name,
    }));
  };

  useEffect(() => {
    getInterventionsItems({ endpoint: "components", filters: undefined }).then(
      (response: any) => {
        setComponents(mapCodeToElement(response.data));
      },
    );
  }, []);

  /**
   * Get subcomponents when components has been selected
   */
  useEffect(() => {
    if (notNull(selectedComposant)) {
      const params = new URLSearchParams();

      if (selectedComposant) {
        params.append("component", selectedComposant);
      }
      getInterventionsItems({ endpoint: "sub_components", filters: params }).then(
        (response) => {
          setSubComponents(mapCodeToElement(response.data));
        },
      );
    }
  }, [selectedComposant]);

  /**
   * Get volets after subcomponent is selected
   */
  useEffect(() => {
    if (notNull(selectedSubComponent)) {
      const params = new URLSearchParams();

      if (selectedComposant) {
        params.append("component", selectedComposant);
      }

      if (selectedSubComponent) {
        params.append("sub_component", selectedSubComponent);
      }
      getInterventionsItems({ endpoint: "volets", filters: params }).then(
        (response) => {
          setInterventions(mapCodeToElement(response.data));
        },
      );
    }
  }, [selectedSubComponent]);

  /**
   * Get existing site types
   */
  useEffect(() => {
    if (notNull(selectedIntervention)) {
      const params = new URLSearchParams();

      if (selectedComposant) {
        params.append("component", selectedComposant);
      }

      if (selectedSubComponent) {
        params.append("sub_component", selectedSubComponent);
      }

      if (selectedIntervention) {
        params.append("volet", selectedIntervention);
      }

      getInterventionsItems({ endpoint: "target_types", filters: params }).then(
        (response: any) => {
          setSites(response.data);
        },
      );
    }
  }, [selectedIntervention]);

  useEffect(() => {
    if (notNull(selectedIntervention)) {
      // Interventions to be selected are only PACOFIDE interventions
      const params = new URLSearchParams();

      if (selectedComposant) {
        params.append("component", selectedComposant);
      }

      if (selectedSubComponent) {
        params.append("sub_component", selectedSubComponent);
      }

      if (selectedIntervention) {
        params.append("volet", selectedIntervention);
      }

      if (selectedSite) {
        Object.entries(SITE_CHOICES[selectedSite].filters).map(([key, value]: any) =>
          params.append(key, value),
        );
      }

      getInterventionsItems({ endpoint: "years", filters: params }).then(
        (response: any) => {
          setYears(response.data);
        },
      );
    }
  }, [selectedSite]);

  useEffect(() => {
    if (notNull(selectedYear)) {
      const params = new URLSearchParams();

      if (selectedComposant) {
        params.append("component", selectedComposant);
      }

      if (selectedSubComponent) {
        params.append("sub_component", selectedSubComponent);
      }

      if (selectedIntervention) {
        params.append("volet", selectedIntervention);
      }

      if (selectedSite) {
        Object.entries(SITE_CHOICES[selectedSite].filters).map(([key, value]: any) =>
          params.append(key, value),
        );
      }

      if (selectedYear) {
        params.append("year", selectedYear);
      }
      const map = Object();
      getInterventionsItems({ endpoint: "regions", filters: params }).then(
        (response: any) => {
          setRegions(Region.fromJsonList(response.data));
          response.data.forEach((region: any) => {
            map[region.pk] = region.nom.toLowerCase();
          });
          setRegionPerId(map);
        },
      );
    }
  }, [selectedYear]);

  useEffect(() => {
    if (selectedRegion) {
      const params = new URLSearchParams([["target_region", selectedRegion]]);

      if (selectedComposant) {
        params.append("component", selectedComposant);
      }

      if (selectedSubComponent) {
        params.append("sub_component", selectedSubComponent);
      }

      if (selectedIntervention) {
        params.append("volet", selectedIntervention);
      }

      if (selectedSite) {
        Object.entries(SITE_CHOICES[selectedSite].filters).map(([key, value]: any) =>
          params.append(key, value),
        );
      }

      if (selectedYear) {
        params.append("year", selectedYear);
      }
      defineRegion(regionsPerId[selectedRegion]);
      getInterventionsItems({ endpoint: "departements", filters: params }).then(
        (response: any) => {
          setDepartements(response.data);
        },
      );
    } else {
      defineRegion("");
    }
  }, [selectedRegion]);

  function getGeoInfos(params: any) {
    getFirmsGeoInfos(params).then((response) => {
      const setter = setters[1];
      setter(response.data.results);
      // Recursive call to getGeoInfos when response.data.next is not null
      if (response.data.next) {
        // Create UrlSearchParams from response.data.next
        const url = new URL(response.data.next);
        params = new URLSearchParams(url.search);
        getGeoInfos(params);
      } else {
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    if (notNull(selectedDepartement) || selectedRegion === 0) {
      const params = new URLSearchParams();

      if (selectedComposant) {
        params.append("interventions_component", selectedComposant);
      }

      if (selectedSubComponent) {
        params.append("interventions_sub_component", selectedSubComponent);
      }

      if (selectedIntervention) {
        params.append("interventions_volet", selectedIntervention);
      }

      if (selectedSite) {
        Object.entries(SITE_CHOICES[selectedSite].filters).map(([key, value]: any) =>
          params.append(key, value),
        );
      }

      if (selectedYear) {
        params.append("interventions_year", selectedYear);
      }

      if (selectedRegion) {
        params.append("region", selectedRegion);
      }

      if (selectedDepartement) {
        params.append("departement", selectedDepartement);
      }

      setLoading(true);
      getGeoInfos(params);
    }
  }, [selectedDepartement, selectedRegion]);

  useEffect(() => {
    if (actionSetMarkers) {
      actionSetMarkers(interventions);
    }
  }, [interventions]);

  return (
    <div
      className={`overlay-inner absolute left-[40px] top-[150px] ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="tracking-inner absolute">
        <div
          className="lc-modal absolute left-0 top-0 w-fit sm:w-[400px] bg-black/50 !p-5 sm:!p-12 z-[999] transition-pacofide"
          data-lc-target="tracking"
        >
          <button
            className="lc-modal-icon-cross absolute right-3 top-3 flex items-center justify-center h-[30px] w-[30px] hover:bg-gray-200 text-white text-2xl z-[2] cursor-pointer rounded-full transition-pacofide"
            data-lc-target="#tracking"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style={{ fill: "currentColor" }}
            >
              <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
            </svg>
          </button>
          <div className="space-y-12 relative">
            <form className="relative text-white">
              <h3 className="flex items-center justify-center space-x-4 text-base font-medium">
                Analyse spatiale
              </h3>
              <div className="space-y-6 mt-6 overflow-y-auto overflow-x-hidden max-h-[300px] md:max-h-none">
                <div className="sm:space-y-6 sm:block sm:w-full">
                  <label className="items-center sm:flex">
                    <span className="block sm:min-w-[100px] text-sm">Composant</span>

                    <SelectComponent
                      options={withAllOption(
                        getSelectOptionsFromMap(components, "Composante"),
                      )}
                      onChange={(value) => changeVariable(value, 0)}
                      customPlaceHolder="Sélectionner une composante"
                      value={selectedComposant}
                    />
                  </label>

                  {notNull(selectedComposant) && (
                    <label className="items-center sm:flex">
                      <span className="block sm:min-w-[100px] text-sm">Sous-comp.</span>

                      <SelectComponent
                        options={withAllOption(
                          getSelectOptionsFromMap(subComponents, "Sous-comp."),
                        )}
                        onChange={(value) => changeVariable(value, 1)}
                        customPlaceHolder="Sélectionner une sous-composante"
                        value={selectedSubComponent}
                      />
                    </label>
                  )}

                  {notNull(selectedSubComponent) && (
                    <label className="items-center sm:flex">
                      <span className="block sm:min-w-[100px] text-sm">Volet</span>

                      <SelectComponent
                        options={withAllOption(
                          getSelectOptionsFromMap(interventions, "Volet"),
                        )}
                        onChange={(value) => changeVariable(value, 2)}
                        customPlaceHolder="Sélectionner un volet"
                        value={selectedIntervention}
                      />
                    </label>
                  )}

                  {notNull(selectedIntervention) && (
                    <label className="items-center sm:flex">
                      <span className="block sm:min-w-[100px] text-sm">Site</span>

                      <SelectComponent
                        options={withAllOption(
                          Object.entries(SITE_CHOICES).map(
                            ([key, value]: any) =>
                              ({
                                label: value.display,
                                value: key,
                              } as any),
                          ),
                        )}
                        onChange={(value) => changeVariable(value, 3)}
                        customPlaceHolder="Sélectionner un site"
                        value={selectedSite}
                      />
                    </label>
                  )}

                  {notNull(selectedSite) && (
                    <label className="items-center sm:flex">
                      <span className="block sm:min-w-[100px] text-sm">Année</span>

                      <SelectComponent
                        options={withAllOption(
                          years.map((value) => ({ label: value, value })),
                        )}
                        onChange={(value) => changeVariable(value, 4)}
                        customPlaceHolder="Sélectionner une année"
                        // className="w-full"
                        value={selectedYear}
                      />
                    </label>
                  )}

                  {notNull(selectedYear) && (
                    <label className="items-center sm:flex">
                      <span className="block sm:min-w-[100px] text-sm">Région</span>

                      <SelectComponent
                        options={withAllOption(
                          regions.map((item: Region) => ({
                            label: item.name,
                            value: item.id,
                          })),
                        )}
                        onChange={(value) => changeVariable(value, 5)}
                        customPlaceHolder="Sélectionner une région"
                        value={selectedRegion}
                      />
                    </label>
                  )}

                  {notNull(selectedRegion, false) && (
                    <label className="items-center sm:flex">
                      <span className="block sm:min-w-[100px] text-sm">
                        Département
                      </span>

                      <SelectComponent
                        options={withAllOption(
                          departements.map((departement: any) => ({
                            label: departement.nom,
                            value: departement.pk,
                          })),
                        )}
                        onChange={(value) => changeVariable(value, 6)}
                        customPlaceHolder="Sélectionner un Département"
                        value={selectedDepartement}
                      />
                    </label>
                  )}
                </div>
              </div>
            </form>
            {/* Add a div pane that covers the prevoous to disable it */}
            {loading && (
              <div className="absolute top-0 m-0 bg-gray-500 bg-opacity-50 h-full w-full"></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
