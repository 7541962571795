import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  LayersControl,
  Marker,
  Popup,
  FeatureGroup,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

import country from "./shapes/nigerAdm01.json";
import countryWithDepartements from "./shapes/regoin_depart_niger.json";
import interventionRegions from "./shapes/intervention.json";
import { getFirmsGeoInfos, getSchoolsGeoInfos } from "services/MappingServices";
import { iconFirm, iconSchool } from "./map/icons";
import DownloadUpload from "./DownloadUpload";
import { API_URL } from "constants";
import ZoomControlWithReset from "./mapping/ZoomWithReset";
import { useOutletContext } from "react-router-dom";
import IndicateurSuivi from "./mapping/IndicateurSuivi";
import IndicateurSuiviButton from "./mapping/IndicateurSuiviButton";
import IndicateurSupperpositionButton from "./mapping/IndicateurSupperpositionButton";
import ModalSupperposition, { MAP_VIEWS } from "./mapping/ModalSupperposition";

/* Note: DO NOT DELETE THIS COMMENT
When using local copy of leaflet css markers are not displayed properly
Following code is meant to solve the issue.
See https://github.com/PaulLeCam/react-leaflet/issues/453#issuecomment-410450387
*/
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const PopupComponent = function ({ marker, intervention_type }) {
  return (
    <Popup>
      <div
        style={{
          display: "grid",
          gap: "3px",
        }}
      >
        <div className="d-flex justify-content-center">
          <img src={marker.image} alt="" width={200} height={150} />
        </div>
        <div>Localisation : {marker.location.full_location} </div>
        <div>Type de structure : {marker.get_structure_type_display} </div>
        <div>Niveau de réalisation : {marker.achievement_level} </div>
        <div>
          Coordonnées : {marker.location.latitude}, {marker.location.longitude}{" "}
        </div>
        Télécharger la{" "}
        <a href={`${API_URL}/structures/${marker.pk}/report/`} target="_blank">
          fiche
        </a>
      </div>
    </Popup>
  );
};

const Mapping = () => {
  const [schools, setSchools] = useState([]);
  const [schoolsComplement, addSchools] = useState();
  const [firms, setFirms] = useState();
  const [bounds, setBounds] = useState();
  const [isFiltersOpen, openFilters] = useState();
  const [selectedRegion, setRegion] = useState("");
  const [isSupOpen, setIsSupOpen] = useState(true);
  const [viewIndex, setCurrentViewIndex] = useState(0);
  const [view, setView] = useState(MAP_VIEWS[viewIndex]);
  const [tileKey, setTileKey] = useState(MAP_VIEWS[viewIndex].id);

  const [user, setUser, setPageTitle] = useOutletContext();

  useEffect(() => {
    if (user && user.regions) {
      let departements = { ...countryWithDepartements };
      departements.features = [];
      for (let index = 0; index < countryWithDepartements.features.length; index++) {
        const element = countryWithDepartements.features[index];
        if (
          element.properties.region.toLowerCase() === user.regions[0]?.toLowerCase()
        ) {
          departements.features.push(element);
        }
      }
      if (departements.features.length) {
        setBounds(L.geoJSON(departements).getBounds());
      } else {
        setBounds(L.geoJSON(country).getBounds());
      }
    } else if (user) {
      setBounds(L.geoJSON(country).getBounds());
    }
  }, [user]);

  // Complete schools with elements of schoolComplement
  // when school is set
  useEffect(() => {
    if (schoolsComplement) {
      // Add elements of schoolComplement to schools
      setSchools([...schools, ...schoolsComplement]);
    }
  }, [schoolsComplement]);

  useEffect(() => {
    setView(MAP_VIEWS[viewIndex]);
    setTileKey(MAP_VIEWS[viewIndex].id);
  }, [viewIndex]);

  const onSuccessfullUpload = null;

  const onEachFeature = (feature, layer) => {};

  return (
    <div
      className="relative w-full"
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      {bounds && (
        <MapContainer bounds={bounds} zoomControl={false}>
          <ZoomControlWithReset bounds={bounds} />
          <FeatureGroup>
            {schools
              ? schools.map((school, index) => {
                  return (
                    <Marker
                      position={[school.location.latitude, school.location.longitude]}
                      icon={iconSchool}
                      key={index}
                    >
                      <PopupComponent marker={school} intervention_type="ecole" />
                    </Marker>
                  );
                })
              : null}
          </FeatureGroup>

          <FeatureGroup>
            {firms
              ? firms.map((firm, index) => {
                  return (
                    <Marker
                      position={[
                        firm.localisation.latitude,
                        firm.localisation.longitude,
                      ]}
                      icon={iconFirm}
                      key={index}
                    >
                      <PopupComponent marker={firm} intervention_type="structure" />
                    </Marker>
                  );
                })
              : null}
          </FeatureGroup>

          {selectedRegion &&
            countryWithDepartements.features.map(
              (departement) =>
                selectedRegion === departement.properties.region.toLowerCase() && (
                  <GeoJSON data={departement} key={departement.properties.OBJECTID} />
                ),
            )}

          <LayersControl position="topright">
            <LayersControl.Overlay name="Contours du pays">
              {<GeoJSON data={country} />}
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Régions d'intervention">
              <FeatureGroup>
                {interventionRegions.features.map((feature) => (
                  <FeatureGroup key={feature.properties.NOMREG}>
                    {<GeoJSON data={feature} style={{ color: "green" }} />}
                    <Popup>
                      <div>
                        <div className="d-flex justify-content-center font-bold">
                          <div> {feature.properties.NOMREG} </div>
                        </div>
                        Télécharger la{" "}
                        <a
                          href={`${API_URL}/regions/${feature.properties.NOMREG}/report/`}
                          target="_blank"
                        >
                          fiche
                        </a>
                      </div>
                    </Popup>
                  </FeatureGroup>
                ))}
              </FeatureGroup>
            </LayersControl.Overlay>
          </LayersControl>
          <TileLayer key={tileKey} {...view.tileLayerInfos} />
        </MapContainer>
      )}

      <IndicateurSupperpositionButton onClick={() => setIsSupOpen(true)} />
      <ModalSupperposition
        isOpen={isSupOpen}
        onClose={() => setIsSupOpen(false)}
        action={setCurrentViewIndex}
        currentIndex={viewIndex}
      />
      <IndicateurSuivi
        isOpen={isFiltersOpen}
        onClose={() => openFilters(false)}
        setters={[setSchools, addSchools]}
        defineRegion={setRegion}
      />
      <IndicateurSuiviButton onClick={() => openFilters(true)} />
    </div>
  );
};

export default Mapping;
